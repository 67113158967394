const prod = (nonce) => {
    return {
        domain: "login.coveaexcelawards.com",
        clientID: "chdL0NiANBUv1fXgncKbCglgFuq9bYJj",
        nonce: nonce,
        redirectUri: "https://test.coveaexcelawards.com/nominate",
        audience: "https://test.coveaexcelawards.com/nominate",
        responseType: "token id_token"
    }
};

const dev = (nonce) => {
    return {
        domain: "innovation-covea-insurance.eu.auth0.com",
        clientID: "chdL0NiANBUv1fXgncKbCglgFuq9bYJj",
        nonce: nonce,
        redirectUri: "http://127.0.0.1:3000/nominate",
        audience: "http://localhost:3000/nominate",
        responseType: "token id_token"
    }
};

export const webAuthConfig = process.env.NODE_ENV === 'development' ? dev : prod;