const prod = {
    apiKey: "RUrqvLQlTScJvAGOvWfRa0rIPlKCEmU1p1C7SY3b",
    getAwardsUrl: 
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    getReasonsUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations?nominee=",
    getNomineesUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    postNominationUrl: 
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations",
    getQuestionsUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    getEmployeesUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/employees?email="
};

const dev = {
    apiKey: "RUrqvLQlTScJvAGOvWfRa0rIPlKCEmU1p1C7SY3b",
    getAwardsUrl: 
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    getReasonsUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations?nominee=",
    getNomineesUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    postNominationUrl: 
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations",
    getQuestionsUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/nominations/awards",
    getEmployeesUrl:
        "https://qgjlaxll43.execute-api.eu-west-2.amazonaws.com/Test/employees?email="
};

export const awardConfig = process.env.NODE_ENV === 'development' ? dev : prod;